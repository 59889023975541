import { React } from 'mcelroy-lib'
import { FormPage } from 'mcelroy-components'
import * as CardPageStories from '../CardPage.stories'

const SaveButton = {
    label: "Save",
    primary: true,
    onClick: async (values, event) => {
        console.log('save')
        console.log(values)
    }
}

const CancelButton = {
    label: "Cancel",
    onClick: (values, event) => {
        console.log('cancel')
        console.log(values)
    }
}

const DeleteButton = {
    label: "Delete",
    caution: true,
    onClick: (values, event) => {
        console.log('delete')
        console.log(values)
    }
}

export default {
    component: FormPage,
    title: 'FormPage',
    tags: ['autodocs'],
    mapping: {
        ...CardPageStories.default.mapping,
        'Note': '* Additional note',
        'Single Primary Button': [SaveButton],
        'Three Buttons': [SaveButton, CancelButton, DeleteButton],
        'undefined': undefined,
        'true': true,
        'false': false,
        'No letter a': function (v) { return (v?.includes?.('a') ? 'Can not contain letter a' : null) },
        'State Options': [
            {
                label: 'United States',
                value: 'US'
            },
            {
                label: 'Canada',
                value: 'CA'
            },
            {
                label: 'Mexico',
                value: 'MX'
            }
        ]
    }
}


export const CheckBox = {
    args: {
        ...CardPageStories.Default.args,
        title: 'Simple Form',
        children: ['undefined', 'Note'],
        'fields[0].type': ['checkbox'],
        'fields[0].label': 'Administrator',
        'fields[0].name': 'userAdmin',
        'fields[0].value': ['true', 'false'],
        'fields[0].disabled': ['false', 'true'],
        'fields[0].show': ['undefined', 'false'],
        buttons: ['Single Primary Button', 'Three Buttons']
    }
}

export const Input = {
    args: {
        ...CardPageStories.Default.args,
        title: 'Simple Form',
        children: ['undefined', 'Note'],
        'fields[0].type': ['undefined', 'password', 'new-password'],
        'fields[0].label': 'Name',
        'fields[0].name': 'userName',
        'fields[0].helperText': ['undefined', 'Enter your name'],
        'fields[0].value': ['Joe'],
        'fields[0].disabled': ['false', 'true'],
        'fields[0].show': ['undefined', 'false'],
        'fields[0].validator': ['undefined', 'No letter a'],
        buttons: ['Single Primary Button', 'Three Buttons']
    }
}

export const SelectBox = {
    args: {
        ...CardPageStories.Default.args,
        title: 'Simple Form',
        children: ['undefined', 'Note'],
        'fields[0].label': 'Location',
        'fields[0].name': 'userLocation',
        'fields[0].helperText': ['undefined', 'Enter your location'],
        'fields[0].value': ['US', ''],
        'fields[0].disabled': ['false', 'true'],
        'fields[0].show': ['undefined', 'false'],
        'fields[0].options': ['State Options'],
        buttons: ['Single Primary Button', 'Three Buttons']
    }
}

export const Section = {
    args: {
        ...CardPageStories.Default.args,
        title: 'Simple Form',
        children: ['undefined', 'Note'],
        'fields[0].label': 'First Section',
        'fields[0].fields[0].label': ['First Name'],
        'fields[0].fields[0].name': ['fname'],
        'fields[0].fields[0].value': ['John'],
        'fields[0].indent': ['undefined', 'true'],
        'fields[0].show': ['undefined', 'false'],
        'fields[1].label': 'Next Section',
        'fields[1].fields[0].label': ['Last Name'],
        'fields[1].fields[0].name': ['lname'],
        'fields[1].fields[0].value': ['Doe'],
        'fields[1].indent': ['undefined', 'true'],
        'fields[1].show': ['undefined', 'false'],
        buttons: ['Single Primary Button', 'Three Buttons']
    }
}
