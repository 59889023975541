import { React, ReactDOM, setOverrideAPI } from 'mcelroy-lib'
import { McElroyApp } from 'mcelroy-components'
import { LiveDocs } from './doc'

const rootElement = document.getElementById('root')
const root = ReactDOM.createRoot(rootElement)

API_OVERRIDE && setOverrideAPI(API_OVERRIDE)

/*
  Define page routes
*/
const routes = [
  {
    path: "/",
    element: <LiveDocs />,
    handle: {
      title: 'Live Docs'
    }
  },
  {
    path: "/:greeting",
    element: <LiveDocs />,
    handle: {
      title: 'Live Docs'
    }
  }
]

/*
  Generate the standard Mcelroy app
*/
root.render(<McElroyApp title="UI Components" routes={routes} login="name only" />)
