import {React} from 'mcelroy-lib'
import { Link } from 'mcelroy-components'

export default {
    component: Link,
    title: 'Link',
    tags: ['autodocs'],
    mapping: {
        'undefined': undefined
    }
}

export const Default = {
    args: {
        children: 'Hello',
        color: ['undefined', 'primary', 'secondary', 'error', 'inherit'],
        variant: ['undefined', 'body', 'body2'],
        tabindex: ['undefined', '1', '2', '-1']
    }
}
