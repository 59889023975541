import { React, TreeView, TreeItem, TextField } from 'mcelroy-lib'
import ReactDomServer from 'react-dom/server'
import * as IconStories from '../components/basic/Icon.stories'
import * as ButtonStories from '../components/basic/Button.stories'
import * as LinkStories from '../components/basic/Link.stories'
import * as CardPageStories from '../components/page-types/CardPage.stories'
import * as BasicPageStories from '../components/page-types/BasicPage.stories'
import * as FormPageStories from '../components/page-types/form-page/FormPage.stories'
import * as TablePageStores from '../components/page-types/table-page/TablePage.stories'
import * as ToastStories from '../components/app/toast.stories'

const docs = [
    IconStories, ButtonStories, LinkStories,
    BasicPageStories, CardPageStories, FormPageStories, TablePageStores,
    ToastStories
]

export function LiveDocs() {
    const [state, dispatch] = React.useReducer(function (state, action) {
        return action
    }, { doc: null, storyName: null })
    return (
        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
            <span style={{ marginRight: '50px' }}><ComponentDocList dispatch={dispatch} /></span>
            <span style={{ flexGrow: 2 }}>
                {state.doc == null ? null : state.storyName == null ? <ComponentDoc doc={state.doc} /> : <ComponentStory doc={state.doc} storyName={state.storyName} />}
            </span>
        </div>
    )
}

function ComponentDocList({ dispatch }) {
    return (
        <TreeView>
            {docs.map((doc, i) => {
                const stories = Object.keys(doc).filter((s) => { return s != 'default' })
                return (
                    <TreeItem key={i} nodeId={'n' + i} label={doc.default.title} onClick={() => { dispatch({ doc: doc, storyName: null }) }} >
                        {stories.map((s, si) => {
                            return (
                                <TreeItem key={si} nodeId={i + '-' + si} label={s} onClick={() => { dispatch({ doc: doc, storyName: s }) }} />
                            )
                        })}
                    </TreeItem>
                )
            })}
        </TreeView>
    )
}

function ComponentDoc({ doc }) {
    const compString = doc.default.component.toString()
    const i1 = compString.indexOf("/*!")
    const i2 = compString.indexOf("*/", i1)
    const text = compString.substring(i1 + 3, i2)
    const de = text.indexOf("@")
    const description = text.substring(0, de > 0 ? de : i2).trim().split('\n')
    const vars = text.substring(de).split('\n').filter((l) => { return l.trim().startsWith("@") })

    return (
        <div>
            <h4>{doc.default.title}</h4>
            <div>{description.map((l, i) => {
                return (
                    <div key={i}>{l}&nbsp;</div>
                )
            })}</div>
            {vars && vars.length > 0 ? <h5>Parameters</h5> : null}
            <div>
                {vars.map((l, i) => {
                    return (
                        <div key={i}>{l.trim().substring(1)}</div>
                    )
                })}
            </div>
        </div>
    )
}

function ComponentStory({ doc, storyName }) {
    const [props, dispatch] = React.useReducer(function (state, action) {
        return action
    }, {})

    React.useEffect(function () {
        const defaultProps = {}
        for (const a in doc[storyName].args) {
            const v = doc[storyName].args[a]
            if (Array.isArray(v)) 
                setPropValue(a, defaultProps, v[0], doc.default.mapping)
            else
                setPropValue(a, defaultProps, v)
        }
        dispatch(defaultProps)
    }, [doc, storyName])

    return (
        <div>
            <ComponentStoryElement doc={doc} storyName={storyName} props={props} />
            <div style={{ display: 'flex', borderTop: '1px solid black', marginTop: '100px', fontSize: '9pt' }}>
                <ComponentStoryEditor doc={doc} storyName={storyName} props={props} dispatch={dispatch} />
                <ComponentStoryCode doc={doc} props={props} />
            </div>
        </div>
    )
}

function ComponentStoryElement({ doc, storyName, props }) {
    const Component = doc.default.component
    if (typeof doc.default.render === 'function') {
        return doc.default.render(props)
    } else {
        const element = <Component {...props} />
        return (
            <div key={storyName}>
                {element}
            </div>
        )
    }
}

function jsonReplacer(key, value) {
    if (value instanceof Function) {
        const str = '\b' + value.toString().replace(/\s\s+/g, ' ') + '\b' // squash spaces and wrap in bs char
        return str
    } else
        return value
}

function ComponentStoryCode({ doc, props }) {
    if (doc.default.tags == null || !doc.default.tags.includes('autodocs'))
        return (null)

    if (typeof doc.default.render === 'function') {
        return (
            <code><pre>
                {doc.default.render.toString()}
            </pre></code>
        )
    }

    let propsString = '' + Object.keys(props)
        .filter((p) => { return p != 'children' })
        .map((p) => {
            if (props[p] === undefined)
                return ''
            if (typeof props[p] == 'string')
                return `\n${p}="${props[p]}"`
            if (Array.isArray(props[p]) && props[p].length > 0 && React.isValidElement(props[p][0]))
                return `\n${p}={${ReactDomServer.renderToStaticMarkup(props[p])}}`
            else if (React.isValidElement(props[p]))
                return `\n${p}={${ReactDomServer.renderToStaticMarkup(props[p])}}`
            else {
                let jsonStr = JSON.stringify(props[p], jsonReplacer, 2)
                    .replace(/"([^"]+)":/g, '$1:') // remove quotes around properties
                
                // loop through each function in jsonStr
                let idx = jsonStr.indexOf('"\\b')
                while (idx != -1) {
                    const start = jsonStr.substring(0, idx)
                    const idx2 = jsonStr.indexOf('\\b"')
                    // get function without wrapped "\\b & \\b"
                    const fun = jsonStr.substring(idx+3, idx2)
                    const end = jsonStr.substring(idx2 + 3)
                    // remove any json excaped quotes
                    jsonStr = start + fun.replaceAll('\\"', '"') + end
                    idx = jsonStr.indexOf('"\\b')
                }
                return `\n${p}={${jsonStr}}`
            }
        }).join("")
    const contentString = '' + Object.keys(props)
        .filter((p) => { return p == 'children' })
        .map((p) => {
            return '\n\t' + ReactDomServer.renderToStaticMarkup(props[p])
        })
    if (propsString.length > 0)
        propsString += '\n'
    const elementString = `<${doc.default.title}${propsString}>${contentString}\n</${doc.default.title}>`

    return (
        <span style={{display: 'inline-block'}}>
            <code><pre>{elementString}</pre></code>
        </span>
    )
}

function ComponentStoryEditor({ doc, storyName, props, dispatch }) {
    const args = doc[storyName].args
    return (
        <span style={{display: 'inline-block', width: '200px', marginRight: '50px'}}>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'stretch' }}>
            {Object.keys(args).map((f, i) => {
                return (
                    <ComponentStoryEditorField key={i} field={f} config={args[f]} doc={doc} props={props} dispatch={dispatch} />
                )
            })}
            </div>
        </span>
    )
}

function ComponentStoryEditorField({ field, config, doc, props, dispatch }) {
    if (typeof config === 'string') {
        return (
            <TextField margin="normal"
                InputLabelProps={{ shrink: true }}
                label={field}
                value={getPropValue(field, props)}
                onChange={(e) => {
                    const ret = { ...props }
                    setPropValue(field, ret, e.target.value)
                    dispatch(ret)
                }}
            />
        )
    } else if (Array.isArray(config) && config.length > 1) {
        const mapping = doc.default.mapping
        const propVal = getPropValue(field, props)
        let fieldValue = propVal
        for (const m in mapping) {
            if (mapping[m] === propVal)
                fieldValue = m
        }
        return (
            <TextField margin="normal"
                select
                InputLabelProps={{ shrink: true }}
                label={field}
                value={fieldValue}
                onChange={(e) => {
                    const ret = { ...props }
                    setPropValue(field, ret, e.target.value, mapping)
                    dispatch(ret)
                }}
                SelectProps={{ native: true }}
            >
                {
                    config.map((o, i) => {
                        return (
                            <option key={i} value={o}>{o}</option>
                        )
                    })
                }
            </TextField>
        )
    }
}

/*
    get value from props with field name of type:
    field
    field.subfield
    field[index].subfield
*/
function getPropValue(fieldName, props) {
    const fields = fieldName.split('.')
    for (const f of fields) {
        let arrayParts = /(.*)\[(.*)\]/.exec(f)
        if (arrayParts) {
            props = props?.[arrayParts[1]]?.[arrayParts[2]]
        } else {
            props = props?.[f]
        }
    }
    return props ?? ''
}

/*
    set value to props with field name of type:
    field
    field.subfield
    field[index].subfield
*/
function setPropValue(fieldName, props, value, mapping) {
    const fields = fieldName.split('.')
    for (let i = 0; i < fields.length - 1; i++) {
        const f = fields[i]
        let arrayParts = /(.*)\[(.*)\]/.exec(f)
        if (arrayParts) {
            if (!props[arrayParts[1]])
                props[arrayParts[1]] = []
            props = props[arrayParts[1]]
            if (!props[arrayParts[2]])
                props[arrayParts[2]] = {}
            props = props[arrayParts[2]]
        } else {
            if (!props[f])
                props[f] = {}
            props = props[f]
        }
    }

    if (mapping && value in mapping)
        props[fields[fields.length - 1]] = mapping[value]
    else
        props[fields[fields.length - 1]] = value
}