import {React} from 'mcelroy-lib'
import { CardPage } from 'mcelroy-components'
import * as BasicPageStories from './BasicPage.stories'

export default {
    component: CardPage,
    title: 'CardPage',
    tags: ['autodocs'],
    mapping: {
        ...BasicPageStories.default.mapping, // Import some basic mappings from the BasicPage stories
        'Simple Content': <span>Card content here.</span>,
        'Single Element Footer': <span>F1</span>,
        'Two Element Footer': [<span key={1}>F1</span>, <span key={2}>F2</span>],
    }
}

export const Default = {
    args: {
        ...BasicPageStories.Default.args, // Import some basic configuration from the Default BasicPage story
        title: 'Example Card',
        children: ['Simple Content'],
        size: ['undefined', 'sm', 'md']
    }
}

export const WithFooter = {
    args: {
        ...Default.args,
        footer: ['Single Element Footer', 'Two Element Footer'],
    }
}
