import { React } from 'mcelroy-lib'
import { useToast } from 'mcelroy-components'

function ToastTest({ message, mode }) {
    const toast = useToast()

    function doToast() {
        toast[mode](message)
    }

    return (<button onClick={doToast}>Do Toast</button>)
}

export default {
    component: useToast,
    title: 'useToast()',
    render: ToastTest
}

export const Default = {
    args: {
        message: 'Hello World!',
        mode: ['error', 'warn', 'info', 'success']
    }
}

