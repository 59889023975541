import {React} from 'mcelroy-lib'
import { BasicPage } from 'mcelroy-components'

export default {
    component: BasicPage,
    title: 'BasicPage',
    tags: ['autodocs'],
    mapping: {
        'undefined': undefined,
        'Hello World': 'Hello World',
        'Simple Content': <span>Content here.</span>
    }
}

export const Default = {
    args: {
        children: ['Simple Content'],
        pageTitle: ['undefined', 'Hello World']
    }
}


