import {React} from 'mcelroy-lib'
import { Icon } from 'mcelroy-components'

export default {
    component: Icon,
    title: 'Icon',
    tags: ['autodocs'],
    mapping: {
        'undefined': undefined
    }
}

export const Default = {
    args: {
        children: ['account_circle', 'add_box'],
        color: ['undefined', 'primary', 'secondary', 'warning', 'error', 'info']
    }
}
