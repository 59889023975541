import { React } from 'mcelroy-lib'
import { TablePage } from 'mcelroy-components'
import * as BasicPageStories from '../BasicPage.stories'

const columns = [
    { title: 'Id', name: 'id' },
    { title: 'First', name: 'first' },
    { title: 'Last', name: 'last', allowUnsafeHTML: true }
]

const data = [
    { id: 1, first: 'John', last: 'Doe&reg;', greeting: 'Hi' },
    { id: 2, first: 'Jane', last: 'Doe', greeting: 'Hello' },
    { id: 3, first: 'Mike', last: 'Smith', greeting: 'Yo' },
    { id: 4, first: 'John', last: 'Smith', greeting: 'Sup' },
    { id: 5, first: 'Eric', last: 'Johnson', greeting: 'Whaaatsuuuup' },
]

export default {
    component: TablePage,
    title: 'TablePage',
    tags: ['autodocs'],
    mapping: {
        ...BasicPageStories.default.mapping, // Import some basic mappings from the BasicPage stories
        'cols': columns,
        'data': data,
        'undefined': undefined,
        'true': true,
        'false': false,
        'No Actions': [],
        'Action Buttons': [
            { label: 'Download', onClick: function () { console.log('Download clicked') } },
            { label: 'Go Home', to: '/'}
        ]
    }
}

export const Default = {
    args: {
        ...BasicPageStories.Default.args, // Import some basic configuration from the Default BasicPage story,
        children: undefined, // remove example children from Default BasicPage story
        columns: ['cols'],
        data: ['data'],
        loading: ['false', 'true'],
        'options.limit': ['undefined', 3],
        'options.rowClickTo': ['undefined', '/:greeting'],
        actions: ['No Actions', 'Action Buttons']
    }
}
