import {React} from 'mcelroy-lib'
import { Button } from 'mcelroy-components'

export default {
    component: Button,
    title: 'Button',
    tags: ['autodocs'],
    mapping: {
        'undefined': undefined,
        'true': true,
        'false': false
    }
}

export const Default = {
    args: {
        children: 'Hello',
        color: ['undefined', 'primary', 'secondary', 'error', 'gray'],
        disabled: ['undefined', 'true', 'false'],
        variant: ['undefined', 'contained', 'outlined']
    }
}
